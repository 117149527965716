<template>
    <div class="login-wrapper">
        
        <div id="login-div">
            <div class="title-holder">
                <span>
                    Fluke
                </span>
                Portal do distribuidor
            </div>
            <div class="login-card">
                <div v-if="!success">
                    Insira seu email abaixo. <br />
                    <br />
                    <div id="form">
                        <input
                            type="text"
                            placeholder="Email"
                            v-model="email" />
                    </div>
                </div>
                <div v-else>
                    Um email foi enviado para você com as instruções para a redefinição de senha.
                    <br>
                    <br>
                    <v-btn
                        large
                        depressed
                        block
                        color="grey"
                        @click="cancel">

                        voltar
                    </v-btn>
                    <br>
                    <br>
                </div>
            </div>
            <div class="btn-aligner" v-if="!success">
                <div class="btns-aux">
                    <span @click="cancel" class="forgot-link">
                        voltar
                    </span>
                </div>
                <div class="btns-main">
                    <button
                        @click="reset">

                        ENVIAR
                    </button>
                </div>
            </div>
            <!-- <v-progress-linear indeterminate></v-progress-linear> -->
        </div>
    </div>
</template>

<script>
import validatorRules from '../../helpers/validator_rules'


export default {
    data: () => ({
        email: '',
        success: false,
        isValid: false
    }),
    computed: {
        requiredRule() {
            return validatorRules.required('Campo obrigatório')
        },
        emailRule() {
            return validatorRules.email('Por favor insira um email válido')
        }
    },
    methods: {
        async reset() {
            this.$refs.form.validate()
            if( !this.isValid ) return false

            const response = await this.$store.dispatch( 'auth/resetPassword', this.email )
            this.success = true
        },
        async cancel() {
            this.$router.push({
                name: 'login'
            })
        }
    }
}
</script>
